<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        The following plot is from a kinetics run of crystal violet and sodium hydroxide.
      </p>

      <p class="mb-4 pl-6">
        <v-img style="max-width: 513px" src="/img/assignments/kineticsRunPlot.png" />
      </p>

      <p class="mb-n3">Which of the following is the pseudo rate law for the reaction?</p>

      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing" class="mb-0 ml-6">
        <v-radio
          v-for="option in options"
          :key="'pt-1-' + option.value"
          class="my-1 ml-3"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '../StembleLatex.vue';

export default {
  name: 'ChemUCI1LCQ3S1Q3',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
      options: [
        {text: 'rate = $\\text{k}[\\text{CV}^+][^-\\text{OH}]$', value: 'a'},
        {text: 'rate = $\\text{k}_1$', value: 'b'},
        {text: 'rate = $\\text{k}_1[\\text{CV}^+]$', value: 'c'},
        {text: 'rate = $\\text{k}[\\text{CV}^+]^2$', value: 'd'},
        {text: 'rate = $\\text{k}[\\text{CV}^+][^-\\text{OH}]^2$', value: 'e'},
      ],
    };
  },
};
</script>
<style scoped></style>
